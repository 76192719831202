import styles from './style.module.css';

const Toast = (props)=>{
	const {show, type} = props;
	const {setShowModal} = props;
	let {error} = props;

	const getMessage = () => {
		if(type === 'error'){
			console.log('error');
			return `${error}`;
		}
		if(type === 'loading'){
			return `Sending Email`;
		}

		return 'Your inquiry has been received'
	}
	const message =  getMessage();

	const getTitle = ()=>{
		if(type === 'error'){
			return `Failed`;
		}
		if(type === 'loading'){
			return `Loading`;
		}

		return 'Sending Email'
	}
	const title = getTitle();

	const getToastType = () => {
		if(type === 'error'){
			return `${styles.error}`;
		}
		if(type === 'loading'){
			return  `${styles.loading}`;
		}
		return `${styles.success}`;
	};

	return(
		<div id={`${styles.toast}`} className={`${getToastType()} ${show ? styles.show : styles.hide}`}>
			<div id={`${styles.component}`}>
				<div id={`${styles.title}`}>
					{title}
				</div>
				<div id={`${styles.message}`}>
					{message}
				</div>
			</div>
			<div id={`${styles.closeBtn}`} onClick={()=>{setShowModal(false)}}>
				X
			</div>
		</div>
	);
}


export default Toast;