import './style.css';

const Article = (props)=> {
	const {selectedImage= 0, isReversed = false, showButton= true, showCarousel = false} = props;
	const {title="", textDescription="", imageSources=[]} = props;

	const Image = ()=>{
		return (
			<>
				{
					imageSources.length > 1 ?
						<div className={'multi-image-container'}>
							<div className={'multi-image-main-image-container'}>
								<img className={'multi-image-main-image'} src={imageSources[selectedImage]} alt={"Main Image"}/>
							</div>
							<div className={'multi-image-slides-container'}>
								{
									imageSources.map(
										(src, idx)=> {
											return (
												<>
													{
														idx > 0 ?
															<div className={'multi-image-image-container'} key={idx}>
																<img className={'multi-image-image'} src={imageSources[idx]}
																     alt={'multi image'}/>
															</div>:
															<></>
													}
												</>
											);
										}
									)
								}
							</div>
						</div>
						:
						<img className={'article-image'} src={imageSources[0]} alt={"Article Image"}/>
				}
			</>
		);
	}
	const Content = () => {
		return (
			<div className={'article-image-container'}>
				<Image/>
			</div>
		);
	}

	const Text = () => {
		return (
			<div className={'article-text-container'}>
				<div className={'article-text-title'}>
					{title}
				</div>
				<div className={'article-text-description'}>
					{textDescription}
				</div>
				<div className={'article-button-container'}>
					{
						showButton ?
							<button className={'article-button'} onClick={()=>{window.location.href = '/contact'}}>Contact Us for More Details</button> :
							<div className={'article-line'}></div>
					}
				</div>
			</div>
		);
	}
	return (
		<div className={'article-container'}>
			{
				window.innerWidth < 1100 ?
					<>
						{Content()}
						{Text()}
					</> :
					isReversed ?
						<>
							{Text()}
							{Content()}
						</> :
						<>
							{Content()}
							{Text()}
						</>
			}
		</div>
	);
}

export default Article;