import './contact.css';
import './contact-mobile.css';
import axios from 'axios';

const Contact = ()=>{
	const Components = (language, show, isLoading, setIsLoading)=>{
		const requirementList = [
			'wooden-pallet',
			'wooden-box',
			'corrugated-box',
			'shipping-service',
			'management',
			'fumigation'
		];
		const generateRequirement = (formData)=>{
			let result = requirementList.map(item=>{
				if(formData.get(item)){
					return item;
				}
			});
			result = result.filter(item=> !!item);
			return result;
		}
		const handleSubmit = async (event)=>{
			event.preventDefault();
			setIsLoading(true);
			show("loading");

			const form = new FormData(event.target);
			const data = {};
			data['company_name'] = form.get('companyName');
			data['company_address'] = form.get('companyAddress');
			data['commodity'] = form.get('commodity');
			data['quantity'] = form.get('quantity');
			data['name'] = form.get('name');
			data['email'] = form.get('email');
			data['phone'] = form.get('phone');
			data['message'] = form.get('message');
			data['requirements'] = generateRequirement(form);

			let response = null;
			try{
				response = await axios.post('https://mailservice.packingmaterial.id/api/inbox', data);
				console.log(response);

				if(response.data.ok){
					show("success");
				}
				setIsLoading(false);
			}catch (e) {
				setIsLoading(false);
				console.log(e.response.data.message);
				show('error', e.response.data.message);
			}
		}

		const InformationForm = (props) => {
			const {language} = props;

			return (
				<div className={'contact-component'} style={{
					width: '40%',
					maxWidth: '800px'
				}}>
					<div id={'information-container'}>
						<div>
							<div id={'location-pin-container'}>
								<img className={'image'} src={'/assets/location_pin.png'}/>
							</div>
							<div className={'information-title'} style={{marginTop: '20px'}}>
								Operational Offices
							</div>
							<div id={'office-location-info'}>
								<div className={'information-title'} style={{marginTop: '20px', marginBottom: '7px'}}>
									Jakarta
								</div>
								<div className={'information-text'}>
									Jl. Letjen Suprapto Blok A/31 Komplek Ruko Mega Grosir Cempaka Mas, Kel. Sumur Batu,
									Kec. Kemayoran,
									Jakarta Pusat 10640, Indonesia.
								</div>
								<div className={'information-title'} style={{marginTop: '20px', marginBottom: '7px'}}>
									Banjarmasin
								</div>
								<div className={'information-text'}>
									Jl. Pramuka Komp. Tirta Darma, Palm View Residence No. D3, Banjarmasin 70249, Indonesia.
								</div>
							</div>
						</div>

						<div style={{marginTop: "12%"}}>
							<div className={'information-icon-container'} >
								<img className={'image'} src={'/assets/phone.png'}/>
							</div>
							<div className={'information-info'}>
								<div className={'information-title'}>
									Phone
								</div>
								<div className={'information-text'}>
									Jakarta: +62(21)42805661<br/>
									Banjarmasin: +62(511)3255778<br/>
									<br/>
									Sales Only: +62 8125114382
								</div>
							</div>
						</div>

						<div style={{marginTop: "12%"}}>
							<div className={'email-icon-container'} >
								<img className={'image'} src={'/assets/email.png'}/>
							</div>
							<div className={'information-info'}>
								<div className={'information-title'}>
									Email
								</div>
								<div className={'information-text'}>
									sales@packingmaterialindonesia.com
								</div>
							</div>
						</div>

						<div style={{marginTop: "12%", marginBottom: "150px"}}>
							<div className={'information-icon-container'} >
								<img className={'image'} src={'/assets/clock.png'}/>
							</div>
							<div className={'information-info'}>
								<div className={'information-title'}>
									Operational Hour
								</div>
								<div className={'information-text'}>
									Monday – Saturday : 09.00 – 17.00
								</div>
							</div>
						</div>

					</div>
				</div>
			);
		}
		const ContactForm = () => {
			const title = language === 'id' ? 'Kirim pesan anda ke kami' : 'Send a Message';
			const companyNameText = language === 'id' ? 'Nama Perusahaan' : 'Company Name';
			const companyAddressText = language === 'id' ? 'Alamat Perusahaan' : 'Company Address';
			const commodityText = language === 'id' ? 'Komoditi' : 'Commodity';
			const qtyText = language === 'id' ? 'Jumlah' : 'Quantity';
			const nameText = language === 'id' ? 'Nama Anda' : 'Your Name';
			const emailText = language === 'id' ? 'Email Anda' : 'Email';
			const phoneText = language === 'id' ? 'Telpon Anda' : 'Phone Number';
			const needsText = language === 'id' ? 'Kebutuhan:' : 'Requirements:';
			const messageText = language === 'id' ? 'Pesan Anda' : 'Your Message';

			const woodenPalletText = language === 'id' ? 'Pallet Kayu' : 'Wooden Pallets';
			const woodenBoxText = language === 'id' ? 'Kotak/Peti Kayu' : 'Wooden Boxes';
			const corrugatedBoxText = language === 'id' ? 'Karton Box' : 'Corrugated Boxes';
			const shippingServiceText = language === 'id' ? 'Shipping or Moving Services' : 'Shipping or Moving Services';
			const managementText = language === 'id' ? 'Warehouse or Inventory Management' : 'Warehouse or Inventory Management';
			const fumigationText = language === 'id' ? 'ISPM#15 Heat Treatment atau Fumigasi' : 'ISPM#15 Heat Treatment or Fumigation';

			return (
				<div className={'contact-component'} style={{width: '60%', maxWidth: '1200px'}}>
					<div id={'form-title'}>{title}</div>
					<form id={'contact-form'} onSubmit={handleSubmit}>
						<div className={'form-component'}>
							<div className={'input-title'}>
								{companyNameText}&#42;
							</div>
							<input id={'companyName'} name={'companyName'} className={'text-input'}/>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{companyAddressText}&#42;
							</div>
							<input id={'companyAddress'} name={'companyAddress'} className={'text-input'}/>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{commodityText}&#42;
							</div>
							<input id={'commodity'} name={"commodity"} className={'text-input'}/>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{qtyText}&#42;
							</div>
							<input id={'quantity'} name={'quantity'} className={'text-input'} type={'number'}/>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{nameText}&#42;
							</div>
							<input id={'name'} name={'name'} className={'text-input'} />
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{emailText}&#42;
							</div>
							<input id={'email'} name={'email'} className={'text-input'} type={'email'}/>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{phoneText}&#42;
							</div>
							<input id={'phone'} name={'phone'} className={'text-input'} />
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{needsText}
							</div>
							<div id={'needs'}>
								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'wooden-pallet'}/>
									<div className={'checkbox-description'}>
										{woodenPalletText}
									</div>
								</div>

								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'wooden-box'}/>
									<div className={'checkbox-description'}>
										{woodenBoxText}
									</div>
								</div>

								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'corrugated-box'}/>
									<div className={'checkbox-description'}>
										{corrugatedBoxText}
									</div>
								</div>

								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'shipping-service'}/>
									<div className={'checkbox-description'}>
										{shippingServiceText}
									</div>
								</div>

								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'management'}/>
									<div className={'checkbox-description'}>
										{managementText}
									</div>
								</div>

								<div className={'checkbox-container'}>
									<input className={'checkbox'} type={'checkbox'} name={'fumigation'}/>
									<div className={'checkbox-description'}>
										{fumigationText}
									</div>
								</div>
							</div>
						</div>

						<div className={'form-component'}>
							<div className={'input-title'}>
								{messageText}&#42;
							</div>
							<textarea id={'message'} name={'message'}/>
						</div>

						<button id={'submitBtn'} type={'submit'} disabled={isLoading}>
							Submit
						</button>
					</form>
				</div>
			);
		}

		return (
			<div className={'page-component'}>
				<div id={'title'}>
					CONTACT
				</div>

				<div id={'main-content'}>
					<ContactForm/>
					<InformationForm language={language}/>
				</div>
			</div>
		);
	}
	const height = '700px';
	const bgImageUrl = '/assets/bg_contact.png';
	const bgMobileUrl = '/assets/bg_contact.png';

	return {
		Components, bgImageUrl, height, bgMobileUrl
	}
}

export default Contact;