import './style.css';
import { CONTACT, PRODUCT, ROOT, SERVICE } from '../../const';

const NavigationBar = (props) => {
	const { navBarType, language, setLanguage, screenType, showMenu = true, setShowMenu, position, route} = props;
	let getFontColor = (name) => {
		let fontColor = '#012D26';
		if (navBarType !== 'default' || screenType === 'mobile') {
			fontColor = '#F5F5F5';
			if (name === window.location.pathname) {
				fontColor = '#E8DE00'
			}
		}
		return fontColor;
	}
	let getFontWeight = (name) => {
		return name === window.location.pathname ? 800 : 450;
	}
	let logoUrl = navBarType === 'default' ? 'assets/logo_green.png' : 'assets/logo_white.png'
	let title = navBarType === 'default' ? '' : 'PT. Packing Material Indonesia';

	const DesktopTitle = () =>{
		return (
			<div id={'nav-bar-title-container'}>
				<img id={'nav-bar-icon'} src={logoUrl} alt={'Logo'}/>
				{title}
			</div>
		);
	}
	const MobileTitle = ()=>{
		return (
			<div id={'nav-bar-title-container'}>
				<img id={'nav-bar-icon'} src={'assets/logo_green.png'} alt={'Logo'}/>
				<div id={'nav-bar-title'}>PT. Packing Material Indonesia</div>
			</div>
		);
	}
	const Title = () => {
		return (
			<>
				{screenType !== 'mobile' ?
					<DesktopTitle/> : <MobileTitle/>
				}
			</>
		);
	}

	const DesktopMenu = ()=>{
		return (
			<div id={'nav-bar-menu-container'}>
				<div className={'nav-menu'}>
					<a href={ROOT} style={{
						color: getFontColor(ROOT),
						fontWeight: getFontWeight(ROOT)
					}}>
						Home
					</a>
				</div>
				<div className={'nav-menu'}>
					<a href={PRODUCT} style={{
						color: getFontColor(PRODUCT),
						fontWeight: getFontWeight(PRODUCT)
					}}>
						Our Products
					</a>
				</div>

				<div className={'nav-menu'}>
					<a href={SERVICE} style={{
						color: getFontColor(SERVICE),
						fontWeight: getFontWeight(SERVICE)
					}}>
						Services
					</a>
				</div>
				<div className={'nav-menu'}>
					<a href={CONTACT} style={{
						color: getFontColor(CONTACT),
						fontWeight: getFontWeight(CONTACT)
					}}>
						Contact
					</a>
				</div>

				<div id={'nav-bar-select-container'}>
					<select
						className={`nav-bar-select ${navBarType === 'default' ? 'nav-bar-select-transparent' : 'nav-bar-select-color'}`}
						onChange={(e) => {
							setLanguage(e.target.value);
						}}>
						<option hidden value={language}>{language === 'id' ? "ID" : "EN"}</option>
						<option value={'id'} className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>ID
						</option>
						<option value={'en'} className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>EN
						</option>
					</select>
				</div>
			</div>
		);
	}
	const MobileMenu = ()=>{
		return (
			<div id={'mobile-nav-menu'} className={showMenu ? 'show' : 'hide'}>
				<div id={'bg'} onClick={() => setShowMenu(false)}></div>
				<div id={'mobile-nav-menu-container'}>
					<div style={{ margin: '25px'}}>
						<img id={'menu-icon'} src={'assets/logo_white.png'}/>
						<div id={'menu-title'}>
							PT. Packing
							Material Indonesia
						</div>
					</div>
					<div style={{marginTop: '65px'}} className={'mobile-menu'}>
						<a href={ROOT} style={{
							color: getFontColor(ROOT),
							fontWeight: getFontWeight(ROOT)
						}}>
							Home
						</a>
					</div>
					<div className={'mobile-menu'}>
						<a href={PRODUCT} style={{
							color: getFontColor(PRODUCT),
							fontWeight: getFontWeight(PRODUCT)
						}}>
							Our Products
						</a>
					</div>
					<div className={'mobile-menu'}>
						<a href={SERVICE} style={{
							color: getFontColor(SERVICE),
							fontWeight: getFontWeight(SERVICE)
						}}>
							Services
						</a>
					</div>

					<div className={'mobile-menu'}>
						<a href={CONTACT} style={{
							color: getFontColor(CONTACT),
							fontWeight: getFontWeight(CONTACT)
						}}>
							Contact
						</a>
					</div>

					<div className={'mobile-menu'}>
						<select
							className={`nav-bar-select nav-bar-select-color`}
							onChange={(e) => {
								setLanguage(e.target.value);
							}}>
							<option hidden value={language}>{language === 'id' ? "ID" : "EN"}</option>
							<option value={'id'}
							        className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>ID
							</option>
							<option value={'en'}
							        className={`${navBarType === 'default' ? '' : 'option-color-bg'}`}>EN
							</option>
						</select>
					</div>
				</div>
			</div>
		);
	}
	const Menu = () => {
		return (
			<>
				{
					screenType !== 'mobile' ?
						<DesktopMenu/> : <MobileMenu/>
				}
			</>
		);
	}

	const Desktop = () => {
		const location =
			route === ROOT ? 'home' :
				route === PRODUCT ? 'product' :
					route === SERVICE ? 'service' : 'contact';

		return (
			<div id={'nav-bar'} className={`${position === 'fixed' ? 'fixed' : 'relative'} ${location}`}>
				<Title/>
				<Menu/>
			</div>
		);
	}

	const Mobile = () => {
		return (
			<>
				<Menu/>
				<div id={'nav-bar'} style={{position: position}}>
					<Title/>
					<div id={'nav-bar-hamburger'} onClick={() => setShowMenu(!showMenu)}>
						<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="currentColor"
						     className="bi bi-list" viewBox="0 0 16 16">
							<path fillRule="evenodd"
							      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
						</svg>
					</div>
				</div>
			</>
		);
	}

	return (
		<div>
			{
				screenType !== 'mobile' ?
					<Desktop/> : <Mobile/>
			}
		</div>
	);
}

export default NavigationBar;