import './style.css';

const Home = (props)=> {
	const {screenType} = props;
	const Company = () => {
		const title = 'PT. Packing Material Indonesia';
		const description =
			'Wooden Pallets, Industrial Packaging, \n' +
			'\n' +
			'Fumigation Services, Warehouse & Inventory \n' +
			'Management, & ISPM#15 Treatment Provider: \n' +
			'ID-015, ID-049, ID-117'
		return (
			<div id={'fc-component'}>
				<div id={'company-info-title'}>
					PT. Packing Material Indonesia
				</div>
				<div id={'company-info-description'}>
					Wooden Pallets, Industrial Packaging
					<br/>
					Fumigation Services, Warehouse & Inventory
					<br/>
					Management, & ISPM#15 Treatment Provider:
					<br/>
					ID-015, ID-049, ID-117
				</div>
				<button id={'company-info-button'} onClick={()=>{window.location.href = '/product'}}>SEE MORE</button>
			</div>
		);
	}

	const Feature = (props) => {
		const {language} = props;
		const title = language === 'id' ? 'Selamat Datang di' : 'Welcome to';
		const subTitle = language === 'id' ? '“Mitra Anda dalam Keunggulan Kemasan”' : "“Your Total Packing Solution”";
		return (
			<div className={'page-component'} id={'feature-info'}>
				<div id={'feature-info-header'}>
					<div id={'feature-info-title'}>
						{title}
						<br></br>PT. Packing Material Indonesia
					</div>
					<div id={'feature-info-sub-title'}>
						{subTitle}
					</div>
				</div>
				<div id={'feature-info-pictures-container'}>
					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/1.png'}/>
						<div className={'feature-info-image-description'}>
							Customisable Wooden Pallets
						</div>
					</div>
					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/2.png'}/>
						<div className={'feature-info-image-description'}>
							Customisable Corrugated Box
						</div>
					</div>
					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/3.png'}/>
						<div className={'feature-info-image-description'}>
							Custom Wooden Crates & Boxes
						</div>
					</div>

				</div>
				<div id={'feature-info-pictures-container'} style={{marginBottom: '5%'}}>

					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/4.png'}/>
						<div className={'feature-info-image-description'}>
							ISPM#15 & Fumigation Services
						</div>
					</div>
					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/5.png'}/>
						<div className={'feature-info-image-description'}>
							Lashing, Choking, Shipping
							& Moving Services
						</div>
					</div>
					<div className={'feature-info-component'} onClick={() => {
						console.log('redirect')
					}}>
						<img className={'feature-info-image'} src={'assets/feature/6.png'}/>
						<div className={'feature-info-image-description'}>
							Warehouse & Inventory Management
						</div>
					</div>

				</div>
			</div>
		);
	}

	const AboutUs = (props) => {
		const {language} = props;
		let title = language === 'id' ?
			'Tentang Kami' : 'About Us';
		let subTitle = language === 'id' ?
			'Kami menyediakan palet kayu, solusi packing dan logistik untuk memastikan semua eksportir memenuhi persyaratan peraturan dan regulasi ISPM#15.' :
			'We provide wooden pallets, packing, and logistic solutions to ensure all exporters meet the requirements of the ISPM#15 rules and regulations.';
		let description = language === 'id' ?
			'Kami adalah perusahaan manufaktur palet kayu dengan sertifikasi ISPM#15, juga menyediakan solusi pengemasan yang dapat disesuaikan untuk pasar domestik dan internasional. Perusahaan kami memiliki rangkaian layanan yang komprehensif, termasuk, namun tidak terbatas pada, pengemasan industri, layanan fumigasi, manajemen gudang dan cargo choking dan lashing.' :
			'We are a wooden pallet manufacturing company with ISPM#15 certifications, also providing customisable packaging solutions for both domestic and international markets. Our company offers a wide range of services, including but not limited to, industrial packaging, fumigation services, warehouse and inventory management.';

		return (
			<div className={'article-component'}>
				<div className={'article-component-image-container'}>
					<img src={'assets/about_us.jpg'} alt={'forklift'} className={'article-component-image'}/>
				</div>
				<div className={'article-component-text'}>
					<div className={'article-component-title'}>{title}</div>
					<div className={'article-component-sub-title'}>{subTitle}</div>
					<div className={'article-component-description'}>{description}</div>
				</div>
			</div>
		);
	}

	const CompanyValue = (props) => {
		const {language} = props;
		const visionText = 'We are your Total Packaging Solutions';
		const missionText = language === "id" ? 'Nilai-nilai produk dan layanan kami dibangun berdasarkan para ahli kami yang berkualitas dan yang  memahami kebutuhan Anda' : "The values of our products and services are built on the skills of quality experts, who understand your actual needs";
		const qualityText = language === "id" ? 'Menyediakan palet/peti bersertifikat ISPM 15, pengemasan dan layanan logistik lainnya' : 'Providing excellent ISPM 15 certified pallets/crates, packaging and other logistics services';

		const Value = (props) => {
			const {title, description} = props;
			return (
				<div className={'value-component-container'}>
					<div className={'value-component-image-container'}>
						<img className={'value-component-image'} src={'assets/star.png'}/>
					</div>
					<div className={'value-component-text-container'}>
						<div className={'value-component-text-title'}>
							{title}
							<div className={'value-component-underline'}></div>
						</div>
						<div className={'value-component-text-description'}>
							{description}
						</div>
					</div>
				</div>
			);
		}

		return (
			<div id={'vm-container'}>
				<div id={'value-section'}>
					<Value title={'Vision'} description={visionText} />
					<Value title={'Quality'} description={qualityText} />
					<Value title={'Mission'} description={missionText} />
				</div>
				<div id={'vm-button-section'}>
					<button id={'vm-button'} onClick={()=>{window.location.href = 'service'}}>See our Services and Facilities</button>
				</div>
			</div>
		);

	}

	const ProjectList = (props)=>{
		const {language} = props;
		return (
			<div className={'page-component'} id={'projects-container'}>
				<div id={'projects-title'}>
					Some of Our Projects:
				</div>
				<div id={'projects-component-container'}>
					<div className={'projects-component-row'}>
						<div className={'projects-component-image-container-l'}>
							<img src={'assets/projects/1-1.jpg'} className={'projects-component-image'}/>
						</div>
						<div className={'projects-component-image-container-m'}>
							<img src={'assets/projects/1-2.jpg'} className={'projects-component-image'}/>
						</div>
					</div>
					<div className={'projects-component-row'}>
						<div className={'projects-component-image-container-m'}>
							<img src={'assets/projects/2-1.jpg'} className={'projects-component-image'}/>
						</div>
						<div className={'projects-component-image-container-l'}>
							<img src={'assets/projects/2-2.jpg'} className={'projects-component-image'}/>
						</div>
					</div>
					<div className={'projects-component-row'}>
						<div className={'projects-component-image-container-l'}>
							<img src={'assets/projects/3-1.jpg'} className={'projects-component-image'}/>
						</div>
						<div className={'projects-component-image-container-m'}>
							<img src={'assets/projects/3-2.jpg'} className={'projects-component-image'}/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const Components = (language)=> {
		return(
			<>
				<Feature language={language}/>
				<AboutUs language={language}/>
				<CompanyValue language={language}/>
				<ProjectList language={language}/>
			</>
		);
	}

	const bgImageUrl = '/assets/bg_home.png';
	const bgMobileUrl = '/assets/bg_mobile_home.png';
	return {
		FirstComponent: Company,
		Components: Components,
		bgImageUrl: bgImageUrl,
		bgMobileUrl: bgMobileUrl
	};
}

export default Home;