import Title from "../../Component/Title";
import Article from "../../Component/Article";

const Service = ()=>{
	const Components = (language)=>{
		const title = language === 'id' ?
			'Pelayanan & Fasilitas' :
			'Our Services & Facilities';

		const subTitle = 'Dedicated Production and Packing Team with Complete Facilities';

		const articleTitle1=  language === 'id' ?
			'Fasilitas Produksi Pallet Kayu ' :
			'Our Own Wooden Pallet Production Line';

		const textDescription1 =  language === 'id' ?
			'Karena palet kayu kami diproduksi sendiri, kami memberikan kualitas terbaik untuk kebutuhan pengemasan Anda. Dengan lini produksi kami sendiri, memiliki kapasitas untuk memenuhi kebutuhan palet Anda dalam jumlah besar dan waktu pengiriman dan juga waktu pengiriman dalam jumlah besar.' :
			'As our wooden pallets are self-manufactured, we provide the best quality for your packaging needs. With our own production line, we are able to cater to your pallet requirements of large quantity and delivery time.';

		const articleTitle2= language === 'id' ?
			'Kapasitas Gudang yang Besar untuk Inventory ' :
			'Warehouse with Large Capacity for Storage ';

		const textDescription2 =  language === 'id' ?
			'Memiliki fasilitas gudang besar akan memastikan Anda  pengalaman logistik yang lebih baik secara keseluruhan. Kapasitas gudang kami yang besar akan memungkinkan Anda untuk melakukan pengiriman secarantepat waktu dan juga meningkatkan fleksibilitas process shipping jika adanya perubahan keadaan.' :
			'Having the facility of large warehouses will ensure you an overall improved logistics experience. Our large warehouse capacity will allow you for a timely delivery and increased flexibility to adapt to changing circumstances. ';

		const articleTitle3=  language === 'id' ?'Lashing & Choking Cargo' : 'Lashing & Choking';
		const textDescription3 =  language === 'id' ?
			'Salah satu spesialisasi kami adalah Lashing & Choking barang heavy duty untuk kargo dan logistik. Metode pengamanan kami menggunakan tali kawat atau kabel yang kuat dan fleksibel, serta sabuk pengikat untuk mengencangkan dan menstabilkan kargo selama pengiriman dan pengangkutan.':
			'One of our specialities are heavy duty lashing & choking for cargo and logistics. Our securing method utilizes strong and flexible wire ropes or cables, and lashing belts to tightly fasten and stabilize cargo during shipping and transportation.';

		const articleTitle4=  language === 'id' ?'ISPM#15 Treatment Provider, Fumigasi Kayu & Kapal' : 'ISPM#15 Treatment Provider, Wood & Ship Fumigation';
		const textDescription4 =  language === 'id' ?
			'Sebagai bagian dari layanan keseluruhan kami, kami juga menyediakan layanan Fumigasi dan pengendalian hama untuk kayu, kapal dan container. Dengan lisensi penuh yang kami miliki, kami memastikan hasil akhir yang positif untuk setiap tugas fumigasi, heat treatment dan ISPM15.' :
			'You need to heat treat or fumigate wood packaging materials for transporting internationally, this is the requirement of International Plant Protection Convention (IPPC). Our company provides different types of wood treatment which includes ISPM#15 and fumigation. Moreover, we also provide fumigation for containers and shipping vessels. ';

		const articleTitle5=  language === 'id' ?'Packing Langsung di Lokasi Anda' : 'On-Site Packing or Off-Site Packing';
		const textDescription5 =  language === 'id' ?
			'Tim operasional kami dilatih dan diperlengkapi untuk beradaptasi bekerja di lingkungan yang berbeda tanpa mengurangi efisiensi dan kualitas pekerjaan mereka. Kami memberikan pelayanan dengan melakukan packing barang anda di daerah anda atau di gudang kami dengan tenaga profesional untuk memastikan packing barang anda kuat dan aman.' :
			'Our packing team are trained and equipped to adapt working in different environments under different circumstances without compromising the efficiency and quality of their work. We provide services by packing your goods at your area or in our warehouses with professional staff to ensure strong and safe packing of your goods.';

		return (
			<div className={'page-component'}>
				<Title
					title={title}
					subTitle={subTitle}
				/>
				<Article
					showButton={false}
					title={articleTitle1}
					textDescription={textDescription1}
					imageSources={
						[
							'/assets/product/product-1-1.png'
						]
					}
				/>
				<Article
					showButton={false}
					isReversed={true}
					title={articleTitle2}
					textDescription={textDescription2}
					imageSources={
						[
							'/assets/service/service-2-0.jpg'
						]
					}
				/>
				<Article
					showButton={false}
					title={articleTitle3}
					textDescription={textDescription3}
					imageSources={
						[
							'/assets/about_us.jpg'
						]
					}
				/>
				<Article
					showButton={false}
					isReversed={true}
					title={articleTitle4}
					textDescription={textDescription4}
					imageSources={
						[
							'/assets/service/service-4-0.jpg'
						]
					}
				/>
				<Article
					showButton={false}
					title={articleTitle5}
					textDescription={textDescription5}
					imageSources={
						[
							'/assets/about_us.jpg'
						]
					}
				/>
			</div>
		);
	}

	const bgImageUrl = '/assets/bg_service.jpg';
	const bgMobileUrl = '/assets/bg_mobile_service.jpg';

	return {
		Components, bgImageUrl, bgMobileUrl
	}
}

export default Service;